import {
  Accordion,
  Button,
  Container,
  InputGroup,
  Nav,
  Navbar,
  Image,
  Modal,
} from "react-bootstrap";

import React, { useState } from "react";
import { elastic as Menu } from "react-burger-menu";
import { Arrowrightw } from "../icons";
import Login from "./parts/Login";
import { SPEEDSIZE_PREFIX } from "../App";

function Header({ device }) {
  const prefix = "https://www.codere.mx";

  const LOGO = `${SPEEDSIZE_PREFIX}/${prefix}/library/${
    new Date() <= new Date("2024-11-02T23:59:00Z")
      ? "Logos/logoHalloweenGreen"
      : "landing-pages/assets/logo"
  }.png`;

  const regis = `${prefix}/registro-deportes?clientType=sportsbook`;

  const [hamburger, setHamburger] = useState(false);
  const [onShow, setOnShow] = useState("");
  const [showLoginModal, setShowLoginModal] = useState(false);

  const data = [
    {
      url: "https://apuestas.codere.mx/es_MX",
      text: "Deportes",
      rel: "nofollow",
      icon: "all-sports",
    },
    {
      url: "https://apuestas.codere.mx/es_MX/overview",
      text: "Deportes En vivo",
      rel: "nofollow",
      icon: "live-now",
    },
    {
      url: "/casino",
      text: "Casino Codere",
      icon: "codere_codere-theme/images/AppIcon.svg",
    },
    {
      url: "/yak",
      text: "Yak",
      icon: "Yak",
    },
    {
      url: "/casino-en-vivo",
      text: "Casino En vivo",
      icon: "LiveDealers",
    },
    {
      url: "/casino-en-vivo",
      text: "Cazadores del Juego",
      icon: "library/Logos/cazadoresDelJuegoHamburgerLogo.png",
    },
    {
      url: "/promociones",
      text: "Promociones",
      icon: "Promotions",
    },
  ];

  const seo_menu = [{ name: "Ruleta" }, { name: "Blackjack" }];

  const hamburgerHandler = () => {
    hamburger ? setOnShow("") : setOnShow("open");
    setHamburger(!hamburger);
  };

  return (
    <>
      <Navbar className="header_nav stroke" variant="dark">
        {device ? (
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex justify-content-start align-items-center w-100">
              <div id="father">
                <div id="outer-container">
                  <Menu
                    id="elastic"
                    left
                    customCrossIcon={false}
                    pageWrapId={"page-wrap"}
                    outerContainerId={"outer-container"}
                    burgerButtonClassName={onShow}
                    isOpen={hamburger}
                    onOpen={hamburgerHandler}
                    onClose={hamburgerHandler}
                  >
                    {data.map((d, k) => (
                      <a
                        key={k}
                        href={
                          d.url.charAt(0) === "/" ? `${prefix}${d.url}` : d.url
                        }
                        rel={d.rel ? d.rel : null}
                        className="bm-menu"
                      >
                        <div className="d-flex align-items-center gap-3">
                          <img
                            width="26"
                            height="26"
                            src={`${SPEEDSIZE_PREFIX}/${prefix}/${
                              d.icon.includes(".")
                                ? d.icon
                                : `library/Logos/2022/${d.icon}.svg`
                            }`}
                          />
                          <span>{d.text}</span>
                        </div>

                        <div className={"arrowright"}>
                          <Arrowrightw />
                        </div>
                      </a>
                    ))}

                    <Accordion className="hamb-accordion">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <span style={{ color: "#79c000" }}>¿Cómo jugar?</span>
                        </Accordion.Header>
                        <Accordion.Body>
                          <ul>
                            {seo_menu.map((s, k) => (
                              <li key={k}>
                                <a
                                  onClick={hamburgerHandler}
                                  href={`${prefix}/library/${s.name.toLocaleLowerCase()}.html`}
                                >
                                  {s.name}
                                  <Arrowrightw />
                                </a>
                              </li>
                            ))}
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </Menu>
                  <main id="page-wrap"></main>
                </div>
              </div>
              <div className="main-logo">
                <a href="https://apuestas.codere.mx/es_MX" rel="nofollow">
                  <img src={LOGO} alt="Codere Casino Online en España" />
                </a>
              </div>
            </div>

            <div id="header_buttons">
              <Button
                onClick={() => setShowLoginModal(true)}
                className="acceder-button header_btn"
              >
                Acceder
              </Button>
              <Button
                rel="nofollow"
                href={regis}
                className="registrate-button header_btn"
              >
                Regístrate
              </Button>
            </div>
          </div>
        ) : (
          <div className="top-groups-container _d-flex w-100 _justify-content-evenly">
            <InputGroup className="d-flex align-items-center">
              <a href="https://apuestas.codere.mx/es_MX" rel="nofollow">
                <Image
                  style={{ maxWidth: 150 }}
                  src={LOGO}
                  alt="Codere Casino Online en España"
                />
              </a>
              <Nav className="me-auto ">
                {data.map((d, k) => {
                  return (
                    <a
                      className="text-uppercase"
                      key={k}
                      href={d.url}
                      rel={d.rel ? d.rel : null}
                    >
                      {d.text}
                    </a>
                  );
                })}
              </Nav>
            </InputGroup>

            <div className="header-group-right gap-2">
              <Button
                onClick={() => setShowLoginModal(true)}
                className="acceder-button header_btn"
              >
                Acceder
              </Button>
              <Button
                href={regis}
                rel="nofollow"
                className="registrate-button header_btn"
              >
                Regístrate
              </Button>
            </div>
          </div>
        )}
      </Navbar>
      <Modal
        className="login-modal"
        show={showLoginModal}
        onHide={() => setShowLoginModal(false)}
      >
        <Modal.Header className="Auth-form-title" closeButton>
          <h3 className="fw-bold">¡Bienvenido de Nuevo!</h3>
        </Modal.Header>
        <Login />
      </Modal>
    </>
  );
}

export default Header;
