import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Lazy } from "swiper";

import "swiper/css";
import "swiper/css/lazy";
import "swiper/css/autoplay";
import { SPEEDSIZE_PREFIX } from "../../App";

function GameSwiper({ games }) {
  const IMAGE_PREFIX = "https://www.codere.mx/library/Game tiles";

  return (
    <div className="game-carousel">
      <Swiper
        modules={[Autoplay, Lazy]}
        lazy={{ loadPrevNext: true, loadPrevNextAmount: 1 }}
        autoplay={{ delay: 2000, disableOnInteraction: false }}
        spaceBetween={15}
        slidesPerView={3}
        loop={true}
      >
        {games.map((item, index) => (
          <SwiperSlide key={index}>
            <a href={item.url} rel={"nofollow"}>
              <img
                width="50"
                height="50"
                src={`${SPEEDSIZE_PREFIX}/${IMAGE_PREFIX}/${item.name}.webp`}
                alt={item.name}
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
export default GameSwiper;
