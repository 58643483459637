// css
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";

// global
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Inicio from "./Components/Inicio";
import StickyFooter from "./Components/parts/StickyFooter";

export const SPEEDSIZE_PREFIX =
  "https://ecdn.speedsize.com/d1928fec-41f1-4078-8fe1-3b4868da9016";

export const regis = `https://www.codere.mx/registro-deportes?clientType=sportsbook`;

const App = () => {
  const [flag, setFlag] = useState(isMobile);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (windowSize.width <= 768 || isMobile) setFlag(true);
    else setFlag(false);
  }, [windowSize]);

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Header device={flag} />

      <Inicio flag={flag} />
      {flag && <StickyFooter />}
      <Footer device={flag} />
    </>
  );
};

export default App;
