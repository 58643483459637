// Codere StickyFooter Footer \\
function StickyFooter() {
  const children = [
    {
      name: "deportes",
      rel: "nofollow",
      url: "https://apuestas.codere.mx/es_MX",
    },
    {
      name: "★ yak ★",
      url: "https://www.codere.mx/yak",
    },
    {
      name: "casino",
      url: "https://www.codere.mx/casino",
    },
  ];
  return (
    <div className="sticky-footer-contatiner">
      {children.map((child, _) => (
        <a
          className="sticky-item"
          rel={child.rel ? child.rel : null}
          key={child.name}
          href={child.url}
        >
          {child.name.toUpperCase()}
        </a>
      ))}
    </div>
  );
}

export default StickyFooter;
