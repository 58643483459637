import counterCSS from "../../CSS/counter.module.css";
import { useCountdown } from "../../Hooks/useCountdown";
import { Fade } from "react-awesome-reveal";

const currentDate = new Date();

function getNextDateTarget() {
  // If it's after Friday 8 AM, find the date of the next coming Wednesday 7 AM
  if (
    currentDate.getUTCDay() > 5 ||
    (currentDate.getUTCDay() === 5 && currentDate.getUTCHours() >= 8)
  ) {
    const nextWednesday = new Date(currentDate);
    nextWednesday.setUTCDate(
      nextWednesday.getUTCDate() + ((3 + 7 - nextWednesday.getUTCDay()) % 7)
    ); // Calculate days until next Wednesday
    nextWednesday.setUTCHours(7, 0, 0, 0); // Set time to 07:00:00
    return nextWednesday.toISOString();
  }

  // If it's between Wednesday 8 AM and Friday 7 AM, find the date of the next coming Friday 7 AM
  if (currentDate.getUTCDay() >= 3 && currentDate.getUTCDay() < 5) {
    const nextFriday = new Date(currentDate);
    nextFriday.setUTCDate(
      nextFriday.getUTCDate() + ((5 + 7 - nextFriday.getUTCDay()) % 7)
    ); // Calculate days until next Friday
    nextFriday.setUTCHours(7, 0, 0, 0); // Set time to 07:00:00
    return nextFriday.toISOString();
  }

  // Otherwise, it's before Wednesday 8 AM, so the target time is Wednesday 7 AM
  const thisWednesday = new Date(currentDate);
  thisWednesday.setUTCDate(
    thisWednesday.getUTCDate() + ((3 + 7 - thisWednesday.getUTCDay()) % 7)
  ); // Calculate days until this Wednesday
  thisWednesday.setUTCHours(7, 0, 0, 0); // Set time to 07:00:00
  return thisWednesday.toISOString();
}

// Example usage:
// console.log(getNextDateTarget());

const Timer = ({ device }) => {
  const dateToCount = getNextDateTarget();

  const [days, hours, minutes, seconds] = useCountdown(dateToCount);
  const ShowCounter = ({ days, hours, minutes, seconds }) => {
    return (
      <>
        <div className={counterCSS.show_counter}>
          {DateTimeDisplay(days, "Dias")}
          <p className={counterCSS.semicol}>:</p>
          {DateTimeDisplay(hours, "Horas")}
          <p className={counterCSS.semicol}>:</p>
          {DateTimeDisplay(minutes, "Minutos")}
          <p className={counterCSS.semicol}>:</p>
          {DateTimeDisplay(seconds, "Segundos")}
        </div>
      </>
    );
  };

  const DateTimeDisplay = (value, type) => {
    return (
      <div className={counterCSS.countdown}>
        <div style={{ display: "flex" }}>
          <div className={counterCSS.count_styling}>
            <p>{Math.floor(value / 10)}</p>
          </div>
          <div className={counterCSS.count_styling}>
            <p>{value % 10}</p>
          </div>
        </div>
        <span className={counterCSS.time_unit}>{type}</span>
      </div>
    );
  };

  return (
    <a
      href="https://apuestas.codere.mx/es_MX"
      rel="nofollow"
      className={counterCSS.banner_info}
    >
      {!device && (
        <Fade duration={600} triggerOnce={true}>
          <div className={counterCSS.banner_title}>LA ESPERA TERMINARÁ EN:</div>
        </Fade>
      )}
      {ShowCounter({ days, hours, minutes, seconds })}
    </a>
  );
};

export default Timer;
