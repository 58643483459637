import { Button } from "react-bootstrap";
import { Arrowrightw } from "../../icons";
import { SPEEDSIZE_PREFIX } from "../../App";

function TopGame({ banner }) {
  const imgPrefix = `${SPEEDSIZE_PREFIX}/https://www.codere.mx/library/Splash page/games`;

  return (
    <div className="topGame">
      <div className="card">
        <div>
          <a href={banner.url}>
            <img
              width="16"
              height="5"
              className="game_logo"
              src={`${imgPrefix}/${banner.name
                .toLowerCase()
                .replaceAll(" ", "-")}-logo.webp`}
              alt={`${banner.name}-logo`}
            />
            {/* <a href={banner.url} rel={"nofollow"}> */}
            <img
              width="18"
              height="10"
              className="topGameImage"
              src={`${imgPrefix}/${banner.name
                .toLowerCase()
                .replaceAll(" ", "-")}.webp`}
              alt={banner.name}
            />
            {/* </a> */}
          </a>
        </div>

        <div className="position-absolute d-flex bottom-0 pe-5 pb-3 flex-row-reverse w-100 justify-content-between">
          <div className="jugar-div">
            <Button
              className="jugar-regis d-flex justify-content-around align-items-center"
              size="lg"
              href={banner.url}
            >
              <span>Juega Ahora</span>
              <div style={{ margin: "2px 0 0 0" }}>
                <Arrowrightw />
              </div>
            </Button>
          </div>
          <div className="game_info">
            <h3 className="topGameTitle">{banner.name}</h3>
            <div className="topGameSponsor">{banner.sponsor}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopGame;
